import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import "./magic.scss";

const Magic = () => (
  <StaticQuery
    query={graphql`
      query {
        internship: file(relativePath: { eq: "img/elements/internship.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        socialab: file(relativePath: { eq: "img/elements/socialab.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <div className="section-container magic-program">
          <div className="container">
            <div className="main-section-heading">
              <h3 className="text-center">Magic Program</h3>
              <p className="text-center">
                Magic is the second phase of Codetrain. We assist selected
                students from our 12 month training program to start their
                careers by matching them to internships or project
                opportunities.
              </p>
            </div>
          </div>
          <div className="row magic-program__content">
            <div className="col-lg-10 offset-lg-1 col-sm-12 col-xs-12">
              <div className="row">
                <div className="col-lg-5 order-lg-7">
                  <div className="magic-program__internship">
                    <h2>Internships</h2>
                    <br />
                    <p>
                      We connect participants in our Magic program to internship
                      opportunities in local and international software
                      companies to enable them get relevant work experience to
                      start their careers.
                    </p>
                    <p>
                      Meqasa.com - Ghana's leading online real estate listing
                      company, TxtGhana - SMS technologies company, Employay -
                      German podcast, and World Trade Center (Accra) are among
                      some of the companies our students did their internships
                      in the past.
                    </p>
                  </div>
                </div>
                <div className="col-lg-7">
                  <Img
                    fluid={data.internship.childImageSharp.fluid}
                    alt="codetrain training program"
                    className="img-fluid program-img"
                  />
                </div>
              </div>
            </div>

            <div className="row magic-program__content">
              <div className="col-lg-10 offset-lg-1 col-sm-12 col-xs-12">
                <div className="row">
                  <div className="col-lg-5">
                    <div className="magic-program__socialab">
                      <h2>Socialab Incubator</h2>
                      <br />
                      <p>
                        Our students have always used their skills to build
                        software solutions to solve social problems such as
                        Kuumba Media, a company that is is empowering African
                        film-makers to reach global markets. Codetrain's
                        Socialab Incubator provides support to students in our
                        Magic program who want to solve some of the world's
                        pressing problems with technologies such as AI,
                        Blockchain, USSD, and Mobile.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <Img
                      fluid={data.socialab.childImageSharp.fluid}
                      alt="codetrain training program"
                      className="img-fluid program-img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }}
  />
)

export default Magic
