import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import "./fellowship.scss"

const Fellowship = () => (
  <StaticQuery
    query={graphql`
      query {
        fellows: file(relativePath: { eq: "img/elements/fellows.jpg" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <div className="section-container fellowship-program">
          <div className="container">
            <div className="main-section-heading">
              <h3 className="text-center">Codetrain Fellowship</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10 offset-lg-1 col-sm-12 col-xs-12">
              <div className="row">
                <div className="col-lg-7">
                  <Img
                    fluid={data.fellows.childImageSharp.fluid}
                    alt="codetrain training program"
                    className="img-fluid program-img"
                  />
                </div>
                <div className="col-lg-5">
                  <div className="fellowship-program__text">
                    <h2>5 Years Fellowship</h2>
                    <br />
                    <p>
                      Starting a successful career requires mentoring and
                      support. The final phase of Codetrain is 5 year Fellowship
                      which allows Codetrain's graduates that have been
                      successfully matched to companies to get continuous
                      professional development.
                    </p>
                    <p>
                      This approach has seen some of our graduates doing
                      exceptionally well in their duties and giving value to
                      their respective employers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <p className="text-center">
            <Link to="/courses" className="btn-custom-primary-normal">
              Enroll now
            </Link>
            {/* <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdaF4MZlrAK7dB5mcDdCeL9lKhVbKyaHOdZ4NeuGrumDKmWeg/viewform"
              className="btn-custom-primary-normal"
            >
              Enroll now
            </a> */}
          </p>
        </div>
      )
    }}
  />
)

export default Fellowship
