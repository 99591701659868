import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import CoronaUpdate from "./coronaUpdate"
import VirtualProgramSlider from "./virtual-program-slider/VirtualProgramSlider.jsx"
import "./programInfo.scss"

const ProgramInfo = () => (
  <StaticQuery
    query={graphql`
      query {
        program: file(relativePath: { eq: "img/elements/programs-page.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <section>
          <div className="section-container training-program">
            <div className="container">
              <div className="main-section-heading">
                <h3 className="text-center">No background in IT required</h3>
                <br />
                <p className="text-center" style={{ color: "#212529" }}>
                  At Codetrain, we teach everything from scratch starting from
                  the fundamentals. Our teachers are very friendly and they will
                  assist you to understand everything that you learn.
                </p>
              </div>
            </div>
            <div className="container">
              <div className="main-section-heading">
                <h3 className="text-center">
                  Professional Career Training In Coding
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10 offset-lg-1 col-sm-12 col-xs-12">
                <div className="row">
                  <div className="col-lg-5 order-lg-6">
                    <Img
                      fluid={data.program.childImageSharp.fluid}
                      alt="codetrain training program"
                      className="img-fluid program-img"
                    />
                  </div>
                  <div className="col-lg-6">
                    <div className="training-program__text">
                      <p>
                        Codetrain is a three-phase program - the 12 month
                        training program, Magic program and our Fellowship
                        program.
                      </p>
                      <p>
                        Our 12 month training program is an intensive bootcamp
                        during which we provide world-class teaching and
                        mentoring to transform anybody (with even no prior
                        programming experience) into a professional software
                        developer and start a successful career.
                      </p>
                      <p>
                        Students get one-on-one teaching, career advice, job
                        interview training, individual and team projects
                        experience in mobile and web technologies.
                      </p>
                      <p>
                        After the 12 month training, hardworking students get
                        the opportunity to be selected for next program called
                        Magic.
                      </p>
                      <p>
                        <Link to="/courses">
                          <button className="btn-outline-custom-secondary-normal">
                            View our courses
                          </button>
                        </Link>
                        <a href="https://forms.gle/3U7Tz9uvT3yXsM3A6">
                          <button className="btn-custom-primary-normal">
                            Enroll now
                          </button>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CoronaUpdate />
          <VirtualProgramSlider />
        </section>
      )
    }}
  />
)

export default ProgramInfo
