import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import "./programInfo.scss"

const CoronaUpdate = () => (
  <StaticQuery
    query={graphql`
      query {
        virtual: file(
          relativePath: { eq: "img/elements/codetrain-advantage.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <div className="section-container training-program">
          <div className="row">
            <div className="col-lg-10 offset-lg-1 col-sm-12 col-xs-12">
              <div className="container">
                <div className="main-section-heading">
                  <h3 className="text-center">The Codetrain Advantage</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <h5 style={{ color: "#d25ca2" }}>
                    Real life experience on projects
                  </h5>
                  <p>
                    We will teach you how to code apps and interactive websites
                    from scratch using industry based simulated projects.
                  </p>
                  <h5 style={{ color: "rgb(70, 128, 195)" }}>
                    Assistance with job interviews
                  </h5>
                  <p>
                    We will help you land job interviews with top companies and
                    then coach you on how to nail them.
                  </p>
                  <h5 style={{ color: "rgb(36, 152, 162)" }}>
                    Entrepreneural opportunities to use the skills
                  </h5>
                  <p>
                    If your dream is to become an entreprenuer using the skills
                    you've learned, we'll help you achieve that.
                  </p>
                  <h5 style={{ color: "rgb(232 131 49)" }}>
                    Mentorship from top CEOs and Developers
                  </h5>
                  <p>
                    You will get mentorship from our global network of top CEOs
                    and software developers.
                  </p>
                </div>
                <div className="col-lg-6">
                  <Img
                    fluid={data.virtual.childImageSharp.fluid}
                    alt="Codetrain online class advantages"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="row"></div>
            </div>
          </div>
        </div>
      )
    }}
  />
)

export default CoronaUpdate
