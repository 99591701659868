import React from "react"
import { Title, Meta } from "react-head"
import MainLayout from "../components/MainLayout"
import ProgramInfo from "../components/program-info/ProgramInfo"
import Magic from "../components/magic/Magic"
import Fellowship from "../components/fellowship/Fellowship"

const ProgramPage = () => {
  return (
    <MainLayout>
      <Title>
        Codetrain Africa | Program - Learn fullstack web and mobile app
        development
      </Title>
      <Meta
        name="description"
        content="Codetrain is a three-phase program - the 12 month training program, 
        Magic program and our Fellowship program. Our 12 month training program is an 
        intensive bootcamp during which we provide world-class teaching and mentoring 
        to transform anybody (with even no prior programming experience) into a professional 
        software developer and start a successful career."
      />
      <ProgramInfo />
      <Magic />
      <Fellowship />
    </MainLayout>
  )
}

export default ProgramPage
