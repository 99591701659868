import React from "react"
import { Link } from "gatsby"
import "./virtualProgramSlider.scss"

import background from "../../../assets/img/full-photos/codetrain-virtual-training-02.png"
import quality from "../../../assets/img/full-photos/codetrain-virtual-training-03.png"
import additional from "../../../assets/img/full-photos/codetrain-virtual-training-04.png"
import support from "../../../assets/img/full-photos/codetrain-virtual-training-05.png"

const VirtualProgramSlider = () => {
  return (
    <div className="section-container virtual-program-slider-section">
      <div className="container virtual-program-slider-section__content">
        <div className="container">
          <div className="main-section-heading">
            <h3 className="text-center">What to Expect From our Program</h3>
          </div>
        </div>
        <div className="row">
          <div
            id="carouselExampleControls"
            className="carousel slide"
            data-ride="carousel"
            data-interval="10000"
          >
            <div className="carousel-inner">
              <div className="carousel-item">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-xs-12 col-md-4 carousel-item-caption">
                    <div className="carousel-description">
                      <h3 className="carousel-title">World Class standards</h3>
                      <p className="text-muted">
                        After completing the Codetrain program, you’ll have both
                        the technical experience and essential professional
                        skills to compete globally. You will have what it takes
                        to work in top companies anywhere in the world.
                      </p>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-6 carousel-item-image">
                    <img
                      src={quality}
                      className="img-fluid mx-auto d-block"
                      alt="The same academic quality as our in-person classes"
                    />
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </div>
              <div className="carousel-item active">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-xs-12 col-md-4 carousel-item-caption">
                    <div className="carousel-description">
                      <h3 className="carousel-title">
                        No background in IT required to join
                      </h3>
                      <p className="text-muted">
                        Our unique, interactive and patient learning methods
                        suits everybody, especially those with no background in
                        computer science or IT. We start teaching you the
                        fundamentals from scratch and then we make step by step
                        progress. Our teachers are very patient and friendly.
                      </p>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-6 carousel-item-image">
                    <img
                      src={background}
                      className="img-fluid mx-auto d-block"
                      alt="No need to have a background in IT to join"
                    />
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-xs-12 col-md-4 carousel-item-caption">
                    <div className="carousel-description">
                      <h3 className="carousel-title">
                        Our Teachers are Super Friendly and Patient
                      </h3>
                      <p className="text-muted">
                        Our teachers are super friendly and patient and they are
                        always happy to help you to understand the concepts. Our
                        teachers are available throughout the week for
                        one-on-one sessions to assist you with any challenges
                        that you may have.
                      </p>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-6 carousel-item-image">
                    <img
                      src={additional}
                      className="img-fluid mx-auto d-block"
                      alt="Get additional teaching from our teaching fellows"
                    />
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-xs-12 col-md-4 carousel-item-caption">
                    <div className="carousel-description">
                      <h3 className="carousel-title">
                        Learning is based on practical concepts and industry
                        projects
                      </h3>
                      <p className="text-muted">
                        Learning at Codetrain is a unique and exciting
                        experience. Our program is based on industry simulated
                        projects that will equip you with the needed skills to
                        have a successful career.
                      </p>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-6 carousel-item-image">
                    <img
                      src={support}
                      className="img-fluid mx-auto d-block"
                      alt="Increased levels of support and attention"
                    />
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleControls"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleControls"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
            <br />
            <br />
            <p className="text-center">
              <Link to="/courses" className="btn-custom-primary-normal">
                Enroll now
              </Link>
              {/* <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSdaF4MZlrAK7dB5mcDdCeL9lKhVbKyaHOdZ4NeuGrumDKmWeg/viewform"
                className="btn-custom-primary-normal"
              >
                Enroll now
              </a> */}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VirtualProgramSlider
